import React from 'react';

import { FacebookIcon, TwitterIcon } from '../Icons/icons';

export default function Share(props) {
  const { style, title } = props;
  const currentPage = typeof window !== 'undefined' && window.location.href;
  return (
    <ul
      className="shareList"
      style={{ display: `flex`, alignItems: `center`, gap: `15px` }}
    >
      <li className="noBullet label">Share it: </li>
      <li className="noBullet">
        <a
          className="btnLink"
          title="Share on Facebook"
          href={`https://facebook.com/sharer/sharer.php?u=${currentPage}`}
          rel="noopener noreferrer"
          target="_blank"
          style={{ display: `flex` }}
        >
          <FacebookIcon size="36px" />
        </a>
      </li>
      <li className="noBullet">
        <a
          className="btnLink"
          title="Share on Twitter"
          href={`https://twitter.com/intent/tweet/?text=${title}&url=${currentPage}`}
          rel="noopener noreferrer"
          target="_blank"
          style={{ display: `flex` }}
        >
          <TwitterIcon size="36px" />
        </a>
      </li>
    </ul>
  );
}
